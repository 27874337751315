import React from 'react'


const Career = () => {
  return (
    <center>

      <h1 style={{height:"50vh"}}>
        Soon !
      </h1>
    </center>
  )
}

export default Career
