import React from 'react'
import { Container } from 'react-bootstrap'

const OurPartners = () => {
  return (
    <Container>
        
    <h2 className="text-center text-decoration-underline text-dark">Our Partners</h2>
    
    </Container>
    
  )
}

export default OurPartners